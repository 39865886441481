/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  FC, useState, useEffect, useContext,
} from 'react';
import { PageProps, navigate } from 'gatsby';

import Layout from '@/common/ui/template/Layout';
import SEO from '@/newcar/ui/common/organism/SEO';
import BreadcrumbSchemaMarkup from '@/newcar/ui/common/molecule/BreadcrumbSchemaMarkup';
import Header from '@/newcar/ui/common/organism/Header';
import CarmoBreadcrumb from '@/newcar/ui/lineup/organism/CarmoBreadcrumb';
import CarmoFooter from '@/newcar/ui/lineup/organism/CarmoFooter';
import Footer from '@/newcar/ui/common/organism/Footer';
import EntryBreakdownManagerStoreContext from '@/newcar/ui/common/context/EntryBreakdownManagerStoreContext';

import CarModelPanel from '@/newcar/ui/lineup/organism/CarModelPanel';
import CarModelRankingPanelPC from '@/newcar/ui/lineup/organism/CarModelRankingPanelPC';
import CarModelRankingPanel from '@/newcar/ui/lineup/organism/CarModelRankingPanel';
import ModalDetailCondition from '@/newcar/ui/lineup/organism/ModalDetailCondition';
import SearchResultHeader from '@/newcar/ui/lineup/organism/SearchResultHeader';
import SearchResultDesktop from '@/newcar/ui/lineup/organism/SearchResultDesktop';
import Accordion from '@/newcar/ui/lineup/molecule/Accordion';
import SearchResultFooter from '@/newcar/ui/lineup/organism/SearchResultFooter';
import EmptyStateLineupPage from '@/newcar/ui/lineup/organism/EmptyStateLineupPage';
import CarModelPanelSkeletonScreen from '@/newcar/ui/lineup/organism/CarModelPanelSkeletonScreen';
import ScrollToTop from '@/common/ui/organism/ScrollToTop';

// 上部バナー
import ab202408Banner from '@/newcar/ui/lineup/img/ab2024008Banner.png';

// 三冠オリックス画像
import tripleCrown from '@/newcar/ui/lineup/img/triple_crown.svg';
import tripleCrownCarmoLogo from '@/newcar/ui/lineup/img/triple_crown_carmo_logo.jpg';
import reasonRecommend from '@/newcar/ui/lineup/img/reasonRecommend.svg';
import reasonSupport from '@/newcar/ui/lineup/img/reasonSupport.svg';
import reasonCost from '@/newcar/ui/lineup/img/reasonCost.svg';
import reasonRecommendSp from '@/newcar/ui/lineup/img/reasonRecommendSp.svg';
import reasonSupportSp from '@/newcar/ui/lineup/img/reasonSupportSp.svg';
import reasonCostSp from '@/newcar/ui/lineup/img/reasonCostSp.svg';
import orix from '@/newcar/ui/lineup/img/orix_logo.jpg';

// 権威コンテンツ
import iconArrowWhite from '@/newcar/ui/lineup/img/arrow.svg';
import salesAndCars from '@/newcar/ui/lineup/img/salesandcars.png';

// ご利用の流れ画像
import flowStep1 from '@/newcar/ui/lineup/img/flow-step1.png';
import flowStep2 from '@/newcar/ui/lineup/img/flow-step2.png';
import flowStep3 from '@/newcar/ui/lineup/img/flow-step3.png';
import flowStep4 from '@/newcar/ui/lineup/img/flow-step4.png';

// util;
import Log from '@/common/util/log';
import { GTM } from '@/common/util/gtm';
import CARMO_CONST from '@/common/util/const';
// import CARMO_UTIL from '@/common/util/util';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import URI from '@/newcar/util/uri';
import {
  SeoText, BASE_TEXT, seoDataExists, fetchData,
} from '@/newcar/util/const/seo';
import { findByMakerId as fuelFindByMakerId } from '@/newcar/util/const/fuel';
// core
import CarDigest from '@/common/core/model/carDigest';
import { VehicleTypes } from '@/common/core/model/catalog/vehicleTypes';
import SearchCondition from '@/newcar/core/model/searchCondition';
import { EntryBreakdown } from '@/newcar/core/store/dataset/entryBreakdown';
import { Digest } from '@/newcar/util/const/digest';
import SearchCarStoreContext from '@/newcar/ui/common/context/searchCarStoreContext';

import VehicleGrade from '@/common/core/model/catalog/vehicleGrade';
import Util from '@/common/util/util';
import * as styles from './index.module.styl';

type FuelRankingByMaker = {
  makerName: string;
  modelId: string;
  modelName: string;
  fuel: { name: string, value: string };
  price: string;
  photo: string;
  bodyColors: Digest['bodyColors'];
}

const MAKER_NAME: { [K in CARMO_CONST.MAKER.ID]: string } = {
  0: '',
  1: 'レクサス（LEXUS）',
  2: 'トヨタ（TOYOTA）',
  3: '日産（NISSAN）',
  4: 'ホンダ（HONDA）',
  5: 'マツダ（MAZDA）',
  6: 'スバル（SUBARU）',
  7: 'スズキ（SUZUKI）',
  8: 'ダイハツ（DAIHATSU）',
  9: '三菱（MITSUBISHI）',
};

const BODY_TYPE_NAME: { [K in CARMO_CONST.BODY_TYPE.ID]: string } = {
  0: '',
  1: 'セダン',
  2: 'ステーションワゴン',
  3: 'ミニバン',
  4: 'SUV',
  5: '軽自動車',
  6: 'コンパクトカー',
  7: 'ハッチバック',
  9: 'クーペ',
  10: 'その他',
};

const HEADER_ORDER_NAME: { [K in NEWCAR_CONST.SORT]: string } = {
  price: '安い順',
  popularity: '人気順',
  atoz: '50音順',
};

type DigestPageContext = {
  carDigests: CarDigest[];
  selection?: typeof CARMO_CONST.SELECTION.ITEMS[number]
  trialLeaseAssessmentCar?: CarDigest & { vehicleType: VehicleTypes }
}

const TemplatePage: FC<PageProps<{}, DigestPageContext>> = ({ pageContext, location }) => {
  const {
    carDigests,
    selection,
    trialLeaseAssessmentCar,
  } = pageContext;

  // storeにcarDigests格納
  const searchCarStore = useContext(SearchCarStoreContext);
  searchCarStore.setAllCarDigests(carDigests);
  const entryBreakdownManagerStore = useContext(EntryBreakdownManagerStoreContext);

  // お試し審査のグレードを決定
  let trialLeaseAssessmentGrade: VehicleGrade | undefined;
  if (trialLeaseAssessmentCar) {
    const { vehicleGrades } = trialLeaseAssessmentCar.vehicleType;
    // ミライースの場合、「2WD 5ドア X リミテッド SA III 4人 660cc ガソリン DCVT」のグレードを利用する
    if (trialLeaseAssessmentCar.id__normalized.toString() === '10017') {
      trialLeaseAssessmentGrade = vehicleGrades?.find(
        (vehicleGrade) => vehicleGrade.id?.toString() === '63908',
      );
    } else {
      // それ以外は最安値のグレードを取得
      trialLeaseAssessmentGrade = vehicleGrades
        ?.filter((vehicleGrade: VehicleGrade) => vehicleGrade.leasingFee132)
        .reduce((a, b) => (
          a.leasingFee132 && b.leasingFee132 && a.leasingFee132 > b.leasingFee132 ? b : a
        ));
    }
  }

  const [resultList, setResultList] = useState<CarDigest[]>([]);
  const [isSearched, setIsSearched] = useState(false);
  const [headerOrder, setHeaderOrder] = useState(NEWCAR_CONST.SORT.PRICE);
  const [isMakerOnly, setIsMakerOnly] = useState(false);
  const [isBodyOnly, setIsBodyOnly] = useState(false);
  const [makerId, setMakerId] = useState(CARMO_CONST.MAKER.ID.UNSELECTED);
  const [bodyType, setBodyType] = useState(CARMO_CONST.BODY_TYPE.ID.UNSELECTED);
  // SEOテキスト用
  const seoBaseText = BASE_TEXT;
  const [seoData, setSeoData] = useState<SeoText>({
    url: '',
    topText: '',
    headLine: '',
    body: '',
  });
  const [pageTitle, setPageTitle] = useState('');
  const [ogTitle, setOgTitle] = useState('');
  const [twitterTitle, setTwitterTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [breadcrumbSchemaMarkupItems, setBreadcrumbSchemaMarkupItems] = useState<{
    name:string;
    item:string;
  }[]>([]);

  const trialLeaseAssessmentMakerName = trialLeaseAssessmentCar?.makerId ? CARMO_CONST.MAKER.NAME(trialLeaseAssessmentCar.makerId) : '';
  const trialLeaseAssessmentButtonText = `人気の${(makerId && bodyType) || (makerId && !bodyType) ? CARMO_CONST.MAKER.NAME(makerId) : CARMO_CONST.BODY_TYPE.NAME(bodyType)}（${trialLeaseAssessmentCar?.name}）`;

  const meta = !selection ? {
    description: '【ネット割最大49,500円】$${maker}$$$${bodyType}$$の新車$${carsLength}$$台を$${sort}$$で掲載中！頭金・ボーナス払い無しで毎月定額、税金コミコミで安心の新車カーリース（車のサブスク）ならおトクにマイカー 定額カルモくん。メンテナンスも月額定額、契約満了後に車がもらえるプランもご用意。'
      .replace('$${maker}$$', MAKER_NAME[makerId] || '')
      .replace('$${bodyType}$$', BODY_TYPE_NAME[bodyType] || '')
      .replace('【ネット割最大49,500円】の', '【ネット割最大49,500円】')
      .replace('$${carsLength}$$', resultList.length.toLocaleString())
      .replace('$${sort}$$', HEADER_ORDER_NAME[headerOrder]),
  } : {
    title: selection.meta.title.replace('$${carsLength}$$', resultList.length.toString()),
    description: selection.meta.description.replace('$${carsLength}$$', resultList.length.toString()),
  };

  const onUrlChange = async () => {
    // この関数内で変更して使用するもの useStateはワンテンポ遅いため
    let orderby: NEWCAR_CONST.SORT = NEWCAR_CONST.SORT.PRICE;
    // パラメーターによりmetaタグとリストの出し分け
    setResultList([]);
    const searchParams = location.search;
    // 並び順のデフォルトは値段
    if (searchParams) {
      // パラメーターがある場合
      if (
        searchParams.indexOf(
          `${URI.ApiParams.Order}=${NEWCAR_CONST.SORT.POP}`,
        ) >= 0
      ) {
        // 人気順parameterに部分一致のときの処理
        setHeaderOrder(NEWCAR_CONST.SORT.POP);
        orderby = NEWCAR_CONST.SORT.POP;
      } else if (
        searchParams.indexOf(
          `${URI.ApiParams.Order}=${NEWCAR_CONST.SORT.ATOZ}`,
        ) >= 0
      ) {
        // 50音順parameterに部分一致のときの処理
        setHeaderOrder(NEWCAR_CONST.SORT.ATOZ);
        orderby = NEWCAR_CONST.SORT.ATOZ;
      }
    }

    // 初期化
    const condition: SearchCondition = new SearchCondition();
    condition.orderBy = orderby;
    setMakerId(CARMO_CONST.MAKER.ID.UNSELECTED);
    setBodyType(CARMO_CONST.BODY_TYPE.ID.UNSELECTED);
    setIsMakerOnly(false);
    setIsBodyOnly(false);

    const splitted = location.pathname.split('/');
    // todo リファクタリング
    if (splitted[NEWCAR_DEFINE.COL.BODY_TYPE]) {
      // 3分割ならメーカー＋ボディタイプ
      try {
        const tmpMakerKey = splitted[NEWCAR_DEFINE.COL.MAKER_OR_BODY];
        const tmpBodyKey = splitted[NEWCAR_DEFINE.COL.BODY_TYPE];
        const tmpMakerId = CARMO_CONST.MAKER.KEY_TO_ID(tmpMakerKey);
        const tmpBodyTypeId = CARMO_CONST.BODY_TYPE.KEY_TO_ID(tmpBodyKey);
        setMakerId(tmpMakerId);
        setBodyType(tmpBodyTypeId);
        condition.maker = [tmpMakerId];
        condition.body = [tmpBodyTypeId];
        // パンくず構造化データセット
        setBreadcrumbSchemaMarkupItems([
          {
            name: '新車TOP',
            item: process.env.GATSBY_SITE_URL || '',
          },
          {
            name: CARMO_CONST.MAKER.NAME(tmpMakerId),
            item: `${process.env.GATSBY_SITE_URL}/lineup/${tmpMakerKey}/`,
          },
          {
            name: CARMO_CONST.BODY_TYPE.NAME(tmpBodyTypeId),
            item: `${process.env.GATSBY_SITE_URL}/lineup/${tmpMakerKey}/${tmpBodyKey}/`,
          },
        ]);
      } catch (e) {
        // 不正なidの場合
        Log.error(e as never);
        // todo エラーページ遷移
      }
    } else if (splitted[NEWCAR_DEFINE.COL.MAKER_OR_BODY]) {
      // 2分割の場合、ボディ単独かメーカー単独
      try {
        // メーカーで変換を試みる
        const tmpMakerKey = splitted[NEWCAR_DEFINE.COL.MAKER_OR_BODY];
        const tmpMakerId = CARMO_CONST.MAKER.KEY_TO_ID(tmpMakerKey);
        setMakerId(tmpMakerId);
        condition.maker = [tmpMakerId];
        setIsMakerOnly(true);
        // パンくず構造化データセット
        setBreadcrumbSchemaMarkupItems([
          {
            name: '新車TOP',
            item: process.env.GATSBY_SITE_URL || '',
          },
          {
            name: CARMO_CONST.MAKER.NAME(tmpMakerId),
            item: `${process.env.GATSBY_SITE_URL}/lineup/${tmpMakerKey}/`,
          },
        ]);
      } catch (e) {
        try {
          // ボディタイプで変換を試みる
          const tmpBodyKey = splitted[NEWCAR_DEFINE.COL.MAKER_OR_BODY];
          const tmpBodyTypeId = CARMO_CONST.BODY_TYPE.KEY_TO_ID(tmpBodyKey);
          setBodyType(tmpBodyTypeId);
          condition.body = [tmpBodyTypeId];
          setIsBodyOnly(true);
          // パンくず構造化データセット
          setBreadcrumbSchemaMarkupItems([
            {
              name: '新車TOP',
              item: process.env.GATSBY_SITE_URL || '',
            },
            {
              name: CARMO_CONST.BODY_TYPE.NAME(tmpBodyTypeId),
              item: `${process.env.GATSBY_SITE_URL}/lineup/${tmpBodyKey}/`,
            },
          ]);
        } catch (e1) {
          // どちらでも無い不正なidの場合
          Log.error(e1 as never);
          // todo エラーページ遷移
        }
      }
    } else {
      // プレーンなLineupページ
      // パンくず構造化データセット
      setBreadcrumbSchemaMarkupItems([
        {
          name: '新車TOP',
          item: process.env.GATSBY_SITE_URL || '',
        },
        {
          name: '新車一覧',
          item: `${process.env.GATSBY_SITE_URL}/lineup/`,
        },
      ]);
    }

    await searchCarStore.search(condition);
    setResultList(searchCarStore.resultList);

    Log.trace(
      `params makerId:${condition.maker} / bodyType:${condition.body}`,
    );

    // MetaのTitle、Descriptionを更新
    const title = NEWCAR_DEFINE.META.LINEUP_TITLE(condition.maker, condition.body, orderby);
    // const description = '';
    // NEWCAR_DEFINE.META.LINEUP_DESCRIPTION(
    //   condition.maker, condition.body, orderby,
    // );
    // CARMO_UTIL.setMeta(title, description);
    // CARMO_UTIL.setOgp({ property: 'title', content: title });
    // CARMO_UTIL.setOgp({ property: 'description', content: description });
    // CARMO_UTIL.setTwitterOgp({ name: 'title', content: title });
    // CARMO_UTIL.setTwitterOgp({ name: 'description', content: description });
    setPageTitle(title);
    setOgTitle(title);
    setTwitterTitle(title);

    // Criteo計測タグ発火イベント
    GTM.dataLayer.push({
      event: 'criteo.activate',
      criteoProductIDList: (resultList || []).slice(0, 3).map((car) => `${car.id__normalized || ''}`),
    });

    /** SEOコンテンツ取得 */
    const nowUrl = location.pathname.replace(/^\/+/, '').replace(/\/$/, '');
    // URLに対応するデータがある場合、SEOコンテンツを表示
    if (seoDataExists(nowUrl)) {
      setSeoData(fetchData(nowUrl));
    }

    // 詳細検索ダイアログの選択状態を更新
    // if(refs && refs.detailCondition){
    //   (refs.detailCondition & { bindSearchCondition: () => void }).bindSearchCondition();
    // }
  };

  /* eslint-disable arrow-body-style */
  /* eslint-disable max-len */
  const getFuelRankingItemByMakerId = (id: number, limit = 5): FuelRankingByMaker[] => {
    const itemByMaker = fuelFindByMakerId(id);
    const carList = carDigests.filter((car) => car.publishStatus === NEWCAR_CONST.PUBLISH.STATUS.SALE);
    if (!itemByMaker || carList.length === 0) return [];

    const rankingByConsumption: FuelRankingByMaker[] = itemByMaker.fuelDetails.reduce((result, current) => {
      if (result.length >= limit) return result;
      const matchedCar = carList.find((car) => {
        return car.id__normalized.toString() === current.modelId;
      });
      if (!matchedCar) return result;

      result.push({
        makerName: CARMO_CONST.MAKER.NAME(matchedCar.makerId),
        modelId: current.modelId,
        modelName: current.modelName,
        fuel: {
          name: 'JC08',
          value: current.consumption.toString(),
        },
        price: matchedCar.taxedMinPrice11.toLocaleString(),
        photo: matchedCar.thumbnailUrl,
        bodyColors: matchedCar.bodyColors,
      });
      return result;
    }, [] as FuelRankingByMaker[]);

    return rankingByConsumption.slice(0, limit);
  };
  const fuelRanking = getFuelRankingItemByMakerId(makerId);

  const handleOnClickCtaButton = () => {
    if (!trialLeaseAssessmentCar) return;

    const entryBreakdown = new EntryBreakdown();
    const { ...carDigest } = trialLeaseAssessmentCar;

    entryBreakdown.car = carDigest;
    entryBreakdown.car.makerName = CARMO_CONST.MAKER.NAME(carDigest.makerId);
    entryBreakdown.bodyType = CARMO_CONST.BODY_TYPE.NAME(carDigest.bodyType);
    entryBreakdown.grade = trialLeaseAssessmentGrade;
    entryBreakdown.term = NEWCAR_CONST.TERM.MAX();
    // もらえるオプション料金
    const freeTransferDiffLeasingFeeTaxed = trialLeaseAssessmentGrade?.freeTransferDiffLeasingFeeTaxed132 || 0;
    entryBreakdown.wantCar = { price: freeTransferDiffLeasingFeeTaxed };

    // ミライースの場合
    if (trialLeaseAssessmentCar.id__normalized.toString() === '10017') {
      entryBreakdown.referrerLpMonthlyPrice = carDigest.taxedMinPrice11.toString();
    }

    // 備考(memo)取得
    let memo = `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.LP_QUICK}`;
    memo += NEWCAR_CONST.QUICK.MEMO;
    memo += `|ユーザー経由ページ：/lineup/${CARMO_CONST.MAKER.ID_TO_KEY(carDigest.makerId)}`; // ユーザー経由ページがあれば入れる
    memo += `|クイック審査：${carDigest.name ?? ''}|ユーザー表示月額：${carDigest.taxedMinPrice11 + freeTransferDiffLeasingFeeTaxed}`;
    entryBreakdown.memo = memo;

    entryBreakdownManagerStore.setEntryBreakdown(entryBreakdown);

    entryBreakdownManagerStore.saveToSessionStorage();

    navigate('/contract/contact_info/');
  };

  useEffect(() => {
    if (selection?.condition) {
      const applyCondition = async () => {
        await searchCarStore.search({ ...new SearchCondition(), ...selection.condition });
        setResultList(searchCarStore.resultList);
      };
      applyCondition();
    } else {
      onUrlChange();
    }
    setTimeout(() => {
      setIsSearched(true);
    }, 1000);
  }, [location.href]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.search) {
      Util.setRobots();
    }
  }, []);

  /* モーダル操作 */
  const [modal, setModal] = useState(false);
  const openModal = (): void => setModal(true);
  const closeModal = (): void => setModal(false);

  // クエリの無いURLをcanonicalタグとして設定するためのもの
  const canonical = `${process.env.GATSBY_SITE_URL || 'https://carmo-kun.jp'}${location.pathname.endsWith('/') ? location.pathname : `${location.pathname}/`}`;

  return (
    <Layout canonical={canonical}>
      <SEO
        title={pageTitle}
        ogTitle={ogTitle}
        twitterTitle={twitterTitle}
        {...meta}
      />
      <BreadcrumbSchemaMarkup items={breadcrumbSchemaMarkupItems} />

      <div>
        <Header h1Text={seoData.topText} />
        <CarmoBreadcrumb makerId={makerId} bodyId={bodyType} isList={false} otherPage={selection?.benefit.title} />
        <div className={`has-background-white-ter ${styles.isBorderTopLine}`}>
          <div className="columns is-mobile is-multiline">

            {/** PC用 */}
            <div id="search-result-header-pc" className="is-hidden-touch column is-2-desktop is-margin-5">
              <SearchResultHeader
                isMakerOnly={isMakerOnly}
                makerId={makerId}
                isBodyOnly={isBodyOnly}
                bodyType={bodyType}
                seoTopText={seoData.topText}
                openModal={openModal}
              />
              {/* 上部SEOテキストPC */}
              { seoData.topText && (
                <h1 className="is-size-4 has-text-weight-semibold is-margin-bottom-4">{seoData.topText}</h1>
              )}
              <SearchResultDesktop
                isMakerOnly={isMakerOnly}
                makerId={makerId}
                isBodyOnly={isBodyOnly}
                bodyType={bodyType}
                openModal={openModal}
                isOrder={headerOrder}
                setIsOrder={setHeaderOrder}
              />
            </div>

            {/** SP用 */}
            <div id="search-result-header-sp" className="is-hidden-desktop column is-margin-0 is-full-touch">
              <SearchResultHeader
                isMakerOnly={isMakerOnly}
                isBodyOnly={isBodyOnly}
                makerId={makerId}
                bodyType={bodyType}
                seoTopText={seoData.topText}
                openModal={openModal}
              />
            </div>
            <main className={`column is-full-touch container ${styles.hasBackgroundWhiteter}`}>
              <div id="ab-202408-banner" className={`${styles.ab202408Banner} is-hidden-tablet is-hidden`}>
                <img src={ab202408Banner} alt="定額カルモくんなら選べる！ラク！レスが早い！" />
                <p className={`${styles.ab202408BannerNote} has-text-right`}>※1）7年以上ご契約でもらえるオプション加入時 ※2）通常のご案内時。最大3営業日</p>
              </div>
              <div className={`${styles.panelTopSpace} is-hidden-mobile`} />
              {/** 検索結果一覧 */}
              <div className="columns is-multiline">
                {!isSearched
                  ? [...Array(12).keys()].map((val) => (
                    <div className="column is-3-widescreen is-4-tablet" key={val}>
                      {/** 検索が終わるまではスケルトンを表示する */}
                      <CarModelPanelSkeletonScreen />
                    </div>
                  ))
                  : resultList.map((result: CarDigest, index) => (
                    <div className="column is-3-widescreen is-4-tablet" key={index.toString()}>
                      <CarModelPanel digest={result} />
                    </div>
                  ))}
              </div>

              {/** 検索結果0件表示 */}
              {(isSearched && resultList.length === 0) && (
                <div className={`is-margin-5 box ${styles.contentsBox}`}>
                  <EmptyStateLineupPage />
                </div>
              )}

              {/* 燃費ランキング */}
              { makerId > 0 && fuelRanking.length > 0 && (
                <div className={`is-margin-top-7 ${styles.isMargin5} ${styles.contentsLine}`}>
                  <h3 className="title is-size-4 has-text-centered is-margin-top-7 is-margin-bottom-4">
                    {fuelRanking[0].makerName}
                    の燃費ランキング
                  </h3>
                  <div className="columns is-multiline">
                    {fuelRanking.map((item, index) => (
                      <div className="column is-3-widescreen is-4-tablet">
                        <CarModelRankingPanel rankNumber={index + 1} {...item} />
                        <CarModelRankingPanelPC rankNumber={index + 1} {...item} />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* ご利用までの流れ  */}
              <div id="202312ab1" className={`is-hidden is-hidden-tablet is-margin-5 box ${styles.contentsBox}`}>
                <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-4">ご利用までの流れ</h2>
                <div className=" has-text-centered">
                  <img src={flowStep1} alt="ご利用までの流れステップ1" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep2} alt="ご利用までの流れステップ2" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep3} alt="ご利用までの流れステップ3" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep4} alt="ご利用までの流れステップ4" width="" />
                </div>
              </div>

              {/* 権威コンテンツ */}
              {trialLeaseAssessmentCar && (
                <div className={`is-margin-5 box ${styles.contentsBox}`}>
                  <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-0">自分に合った車がわからない方へ</h2>
                  <div className={`sp-border is-padding-4 ${styles.boxcontentsSize}`}>
                    <div className=" has-text-centered">
                      <img className={`${styles.salesAndCars}`} src={salesAndCars} alt="自動車整備士資格を持つ、カルモくん店長・若林" />
                      <p className={`is-size-6 has-text-right ${styles.fpImagecaption}`}>自動車整備士資格を持つ、カルモくん店長・若林</p>
                    </div>
                    <div className="sp-border is-padding-top-4 is-padding-bottom-4 is-size-5">
                      <p>
                        あなたのライフスタイルや家計にピッタリの車を、豊富な専門知識を持つスタッフが選びます。
                        <span className="has-text-weight-semibold">
                          まずは
                          {trialLeaseAssessmentMakerName}
                          の人気車（
                          {trialLeaseAssessmentCar?.name}
                          ）で審査をお試しいただき、お気軽にご相談ください。
                        </span>
                      </p>
                    </div>
                    <div className="has-text-centered">
                      <div className="has-text-center is-margin-top-3 balloon-campaign-parent">
                        <span className={`is-size-5 has-text-weight-semibold is-relative ${styles.balloonCampaign}`}>
                          <span className={`${styles.hasTextLightGray}`}>相談だけでもOK！</span>
                        </span>
                      </div>
                      <button
                        type="button"
                        className={`${styles.lineupCtabutton}`}
                        onClick={handleOnClickCtaButton}
                      >
                        <div className="has-text-centered">
                          <p className="is-size-4">
                            {trialLeaseAssessmentButtonText}
                            <br />
                            でお試し審査
                          </p>
                          <img src={iconArrowWhite} className={`${styles.iconArrowWhite}`} alt="矢印" />
                        </div>
                      </button>
                      <p className="is-size-5 is-margin-top-1 is-margin-bottom-3">※審査後のキャンセル・プラン変更も可能です</p>
                      <p onClick={() => setIsOpen(!isOpen)} className={`is-size-5 showMore ${styles.buttonMorePlan}`}>
                        {isOpen && 'プラン詳細を閉じる'}
                        {!isOpen && 'プラン詳細を見る'}
                        <span className={`${styles.plusBtn} ${isOpen && styles.active}`} />
                      </p>
                      {isOpen && (
                        <div className={`columns is-desktop is-multiline has-text-left is-size-6 is-padding-5 is-margin-bottom-5 ${styles.planDetail} ${styles.isBorderGreyLighter}`}>
                          <div className="column is-padding-bottom-3">
                            <p className="has-text-weight-semibold">審査申込車種</p>
                            <p className="has-text-grey">
                              {trialLeaseAssessmentMakerName}
                              &nbsp;
                              <br className="is-hidden-mobile" />
                              {trialLeaseAssessmentCar?.name || ''}
                            </p>
                          </div>
                          <div className="column is-padding-bottom-3">
                            <p className="has-text-weight-semibold">グレード</p>
                            <p className="has-text-grey">{trialLeaseAssessmentGrade?.orderName || ''}</p>
                          </div>
                          <div className="column is-padding-bottom-3">
                            <p className="has-text-weight-semibold">オプション装備</p>
                            <p className="has-text-grey">
                              なし
                            </p>
                          </div>
                          <div className="column is-padding-bottom-3">
                            <p className="has-text-weight-semibold">賃貸期間</p>
                            <p className="has-text-grey">
                              132ヶ月 11年
                            </p>
                          </div>
                          <div className="column is-padding-bottom-3">
                            <p className="has-text-weight-semibold">月額料金</p>
                            <p className="has-text-grey">
                              {trialLeaseAssessmentCar?.taxedMinPrice11.toLocaleString()}
                              円/月（税込）
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* ご利用までの流れ  */}
              <div id="202312ab2" className={`is-hidden is-hidden-tablet is-margin-5 box ${styles.contentsBox}`}>
                <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-4">ご利用までの流れ</h2>
                <div className=" has-text-centered">
                  <img src={flowStep1} alt="ご利用までの流れステップ1" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep2} alt="ご利用までの流れステップ2" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep3} alt="ご利用までの流れステップ3" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep4} alt="ご利用までの流れステップ4" width="" />
                </div>
              </div>

              {/* 提携先  */}
              <div className={`is-margin-5 box ${styles.contentsBox}`}>
                <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-4">提携先</h2>
                <div className=" has-text-centered">
                  <img src={orix} alt="オリックス自動車" width="250px" />
                </div>
                <div className="sp-border is-padding-4 is-margin-top-4">
                  <p>おトクにマイカー 定額カルモくんは、オリックス自動車株式会社との提携サービスとなります。</p>
                  <p>お客様にお届けする新車は、すべてオリックス自動車が扱う正規ディーラー車です。</p>
                </div>
              </div>

              {/* ご利用までの流れ  */}
              <div id="202312ab3" className={`is-hidden is-hidden-tablet is-margin-5 box ${styles.contentsBox}`}>
                <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-4">ご利用までの流れ</h2>
                <div className=" has-text-centered">
                  <img src={flowStep1} alt="ご利用までの流れステップ1" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep2} alt="ご利用までの流れステップ2" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep3} alt="ご利用までの流れステップ3" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep4} alt="ご利用までの流れステップ4" width="" />
                </div>
              </div>

              {/* 三冠  */}
              <div className={`is-margin-5 box ${styles.contentsBox}`}>
                <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-1">国内No.1カーリース</h2>
                <figure className="is-margin-bottom-4 has-text-centered">
                  <img className={styles.sankanDisplay} src={tripleCrown} loading="lazy" alt="三冠獲得" width="293" />
                </figure>
                <div className="is-margin-bottom-6 has-text-centered">
                  <img src={tripleCrownCarmoLogo} alt="お得にマイカー 定額カルモくん" width="164" />
                </div>
                <div className={`has-text-centered is-hidden-mobile ${styles.sankanArea}`}>
                  <img className={`${styles.sankanItem}`} src={reasonRecommend} alt="カーリース利用経験者が選ぶ、おすすめしたいと思うカーリース No.1" />
                  <img className={`${styles.sankanItem}`} src={reasonSupport} alt="カーディーラー勤務者が選ぶサポートが充実していると思うカーリース No.1" />
                  <img className={`${styles.sankanItem}`} src={reasonCost} alt="ファイナンシャルプランナーが選ぶ、コストパフォーマンスが高いと思うカーリースNo.1" />
                </div>
                <div className={`has-text-centered is-hidden-tablet ${styles.sankanArea}`}>
                  <img className={`is-margin-bottom-4 ${styles.sankanItemSp}`} src={reasonRecommendSp} alt="カーリース利用経験者が選ぶ、おすすめしたいと思うカーリース No.1" />
                  <img className={`is-margin-bottom-4 ${styles.sankanItemSp}`} src={reasonSupportSp} alt="カーディーラー勤務者が選ぶサポートが充実していると思うカーリース No.1" />
                  <img className={`is-margin-bottom-4 ${styles.sankanItemSp}`} src={reasonCostSp} alt="ファイナンシャルプランナーが選ぶ、コストパフォーマンスが高いと思うカーリースNo.1" />
                </div>
                <p className="has-text-grey is-size-7 is-padding-right-4 is-padding-left-4 is-margin-bottom-4">
                  2024年1月期_ブランドのイメージ調査（調査1～3） 調査機関：日本マーケティングリサーチ機構 調査期間：2023年12月14日～2024年1月9日 n数：227（※調査1）、103（※調査2）、177（※調査3）/調査方法：Webアンケート 調査対象者：https://jmro.co.jp/r01525/ 備考：本調査は個人のブランドに対するイメージを元にアンケートを実施し集計しております。/本ブランドの利用有無は聴取しておりません。/効果効能等や優位性を保証するものではございません。/競合2位との差は5％以上。
                </p>
              </div>

              {/* ご利用までの流れ  */}
              <div id="202312ab4" className={`is-hidden is-hidden-tablet is-margin-5 box ${styles.contentsBox}`}>
                <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-4">ご利用までの流れ</h2>
                <div className=" has-text-centered">
                  <img src={flowStep1} alt="ご利用までの流れステップ1" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep2} alt="ご利用までの流れステップ2" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep3} alt="ご利用までの流れステップ3" width="" />
                </div>
                <div className=" has-text-centered">
                  <img src={flowStep4} alt="ご利用までの流れステップ4" width="" />
                </div>
              </div>

              {/** SEO PC用 */}
              { seoData.headLine && seoData.body && (
              <div className={`is-margin-5 box is-hidden-touch has-background-white has-text-grey-dark ${styles.seoBox} ${styles.radiusMini}`}>
                <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-4">{seoData.headLine}</h2>
                <div className="is-size-6 is-padding-right-4 is-padding-left-4">
                  <p>{seoBaseText}</p>
                  <p>{seoData.body}</p>
                </div>
              </div>
              )}

              { seoData.headLine && seoData.body && (
              <div className="is-hidden-desktop is-margin-5">
                <Accordion
                  headLine={seoData.headLine}
                  seoBody={seoData.body}
                />
              </div>
              ) }
              { selection && (
                <div className={`is-margin-top-7 is-margin-5 box is-hidden-tablet ${styles.contentsBox}`}>
                  <h2 className="title is-size-4 has-text-centered is-margin-top-6 is-margin-bottom-7">{`「${selection.benefit.title}」で選ぶメリット`}</h2>
                  <p className="is-padding-4 is-padding-top-0" dangerouslySetInnerHTML={{ __html: selection.benefit.description }} />
                </div>
              )}

            </main>

            {/** 検索結果フッター */}
            <SearchResultFooter
              openModal={openModal}
              isOrder={headerOrder}
              setIsOrder={setHeaderOrder}
            />

            <CarmoFooter />
          </div>

          {/** モーダル */}
          <div data-type="custom-parent" className={`modal search-dialog ${styles.pointerEventsAuto} ${modal ? 'is-active' : styles.close}`}>
            <div className="modal-background" onClick={() => closeModal()} />
            <div className={`modal-card ${styles.pointerEventsAuto}`}>
              <ModalDetailCondition closeModal={closeModal} condition={{ ...new SearchCondition(), ...(selection?.condition ?? {}) }} />
            </div>
          </div>

        </div>
      </div>
      <Footer className={`${styles.isLineupFooter}`} />
      <ScrollToTop className={`${styles.isLineupPageToTop}`} />
    </Layout>
  );
};

export default TemplatePage;
